<template>
	<modal-layout title="Fund wallet">
		<form class="flex flex-col gap-[16px]" v-if="showAmount" @submit.prevent="makePayment(company_id)">
			<div class="form-group">
				<label class="form-label">Amount</label>
				<input v-model="amount" required type="number" class="form-control"/>
			</div>
			<div class="form-group">
				<label class="form-label">Description</label>
				<input v-model.trim="desc" required type="text" class="form-control"/>
			</div>
			<div class="flex flex-col gap-2 mt-[50px]">
				<button :disabled="loading" type="submit" class="btn btn-primary w-100">
					{{ loading ? 'Processing...' : 'Make payment' }}
				</button>
				<button class="text-center text-sm text-black border-0 bg-transparent" type="button" @click="goBack">Go back</button>
			</div>
		</form>
		<div class="flex flex-col gap-6" v-if="showNuban">
			<div class="flex flex-col gap-6" v-if="currentWallet?.wallet.nuban_addresses.length">
				<p class="text-[#6E717C] m-0 text-xs">Fund your wallet by making a transfer to the bank account below.</p>
				<div class="p-4 flex flex-col gap-3 bg-[#F9FBFA]">
					<p class="text-[#575A65] m-0 text-xs">ACCOUNT DETAILS</p>
					<div class="flex flex-col gap-1">
						<p class="text-[#6E717C] m-0 text-xs">Bank Name</p>
						<p class="text-[#000005] m-0 text-sm font-medium">{{ currentWallet?.wallet.nuban_addresses[0].bank_name  }} </p>
					</div>
					<div class="flex flex-col gap-1">
						<p class="text-[#6E717C] m-0 text-xs">Account Number</p>
						<p class="text-[#000005] m-0 text-sm font-medium flex items-center gap-2">
							{{ currentWallet?.wallet.nuban_addresses[0].account_number  }} 
							<span><img src="@/assets/img/icons/wallet/copy2.svg" alt=""></span>
						</p>
					</div>
					<div class="flex flex-col gap-1">
						<p class="text-[#6E717C] text-xs m-0">Account Name</p>
						<p class="text-[#000005] text-sm font-medium flex items-center gap-2 m-0">
							{{ currentWallet?.wallet.nuban_addresses[0].account_name  }} 
							<span><img src="@/assets/img/icons/wallet/copy2.svg" alt=""></span>
						</p>
					</div>
				</div>
				<div class="flex items-start gap-4">
					<img src="@/assets/img/icons/wallet/info.svg" alt="">
					<p class="text-xs text-[#6E717C] m-0">Your wallet will be automatically funded once payment is received. Funding via bank transfer takes 5 - 10 minutes.</p>
				</div>
			</div>
			<walletBankDetails v-else @activation="activateWallet"
				:obj="currentWallet.wallet.nuban_addresses"
			/>
			
			<button @click="showNuban = false" class="mt-[10px] border rounded-lg flex items-center justify-between p-2">
				<div class="flex items-center gap-2">
					<div class="w-[40px] h-[40px] rounded-lg bg-[#F9FBFA] flex items-center justify-center">
						<img src="@/assets/img/icons/wallet/other_funding.svg" alt="">
					</div>
					<p class="text-[#000005] text-sm m-0">Other funding options</p>
				</div>
				<img src="@/assets/img/icons/wallet/arrow_right_green.svg" alt="">
			</button>
		</div>

		<div v-if="!showNuban && !showAmount" class="flex flex-col gap-4">
			<p class="text-[#6E717C] text-xs m-0">Fund your wallet by making use of any of these payment options.</p>
			<div class="flex flex-col gap-3">
				<button class="flex items-center gap-4 border rounded-lg  p-[16px]" @click="showNuban = true">
					<div class="w-[45px] h-[45px] flex items-center rounded-lg justify-center bg-[#F4F5F4]">
						<img :src="bank" alt="">
					</div>
					<p class="text-[#101211] font-medium text-sm m-0">Fund with Transfer</p>
				</button>
				<button v-for="n in buttons" :key="n.text" class="flex items-center gap-4 border rounded-lg p-[16px]" @click="showAmount = true">
					<div class="w-[45px] h-[45px] flex items-center rounded-lg justify-center bg-[#F4F5F4]">
						<img :src="n.icon" alt="">
					</div>
					<p class="text-[#101211] font-medium text-sm m-0">{{ n.text }}</p>
				</button>
			</div>
		</div>
	</modal-layout>
</template>

<script setup>
import { ref } from 'vue'
import bank from '@/assets/img/icons/wallet/bank.svg'
import card from '@/assets/img/icons/wallet/card.svg'
import ussd from '@/assets/img/icons/wallet/ussd.svg'
import { useFlutterWave, useWallets } from '@/composables/backend/wallet'
import activateWalletVue from '@/views/Company/modals/activateWallet.vue'
import walletBankDetails from '@/views/Company/components/walletBankDetails.vue'
import { useModal } from '@/composables/core/modal'
import modalLayout from '@/layouts/core/modal.vue'
import { useRoute } from 'vue-router/composables'

const route = useRoute()
const { makePayment, amount, desc, loading  } = useFlutterWave()
const { currentWallet, ledgerAccountId } = useWallets()

const company_id = route.params.companyId
const showAmount = ref(false)
const showNuban = ref(false)

const buttons = [
	{text: 'Fund with Bank', icon: bank},
	// {text: 'Fund with Transfer', icon: bank},
	{text: 'Fund with Card', icon: card},
	{text: 'Fund with USSD', icon: ussd}
]

const goBack = () => {
	showAmount.value = false
	amount.value = ''
	desc.value = ''
}

const activateWallet = () => {
	useModal().closeModal()
	ledgerAccountId.value = currentWallet.value?.wallet.ledger_account_id
	setTimeout(() => {
		useModal().openModal(activateWalletVue)
	}, 1000)
	
}

</script>