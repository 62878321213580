<template>
	<div v-if="obj.length" class="p-[24px] flex w-full flex-col gap-6 bg-white rounded-xl max-w-[350px]">
		<div class="flex w-full flex-col gap-2 border-b-gray-300 pb-3" v-for="item in obj">
			<div class="flex flex-row">
				<div class="w-2/4 mr-2">
					<p class=" m-0 text-[#6E717C] text-sm">Bank Name</p>
				</div>
				<div class="w-2/4">
					<p class=" m-0 text-base text-[#000005] font-medium">
						{{ item.bank_name }}
					</p>
				</div>
			</div>

			<div class="flex flex-row">
				<div class="w-2/4 mr-2">
					<p class=" m-0 text-[#6E717C] text-sm">Account Number</p>
				</div>
				<div class="w-2/4">
					<p class=" m-0 text-base text-[#000005] font-medium flex items-center gap-4">
						{{ item.account_number }}
						<button @click="copyData" class="cursor-pointer border-0 bg-transparent">
							<img src="@/assets/img/icons/wallet/copy.svg" alt="">
						</button>
					</p>
				</div>
			</div>

			<div class="flex flex-row">
				<div class="w-2/4 mr-2">
					<p class=" m-0 text-[#6E717C] text-sm">Account Name</p>
				</div>
				<div class="w-2/4">
					<p class=" m-0 text-base text-[#000005] font-medium">
						{{ item.account_name }}
					</p>
				</div>
			</div>
			<div class="flex flex-row">
				<div class="w-2/4 mr-2">
					<p class=" m-0 text-[#6E717C] text-xs">Service Provider</p>
				</div>
				<div class="w-2/4">
					<p class=" m-0 text-[#000005] text-xs font-medium">
						{{ item.provider }}
					</p>
				</div>
			</div>
		</div>

		<p class=" m-0 text-sm text-[#B9BCC8]">Transfer money to the bank account above to fund your wallet, your wallet will be
			credited automatically once payment is verified.</p>

		<button class="bg-black w-2/4 mx-auto border-0 rounded-lg whitespace-nowrap mt-3 py-2 text-white px-4" @click="activateWallet">
			Add Nuban
		</button>
	</div>
	<div v-else class="p-[24px] flex w-full flex-col gap-[24px] bg-white rounded-xl max-w-[350px] relative">
		<div class="flex flex-col">
			<p class="m-0 text-[#6E717C] text-sm">Bank Name</p>
			<p class="m-0 text-base text-[#000005] font-medium">
				Shuttlers bank
			</p>
		</div>

		<div class="flex flex-col">
			<p class="m-0 text-[#6E717C] text-sm">Account Number</p>
			<p class="m-0 text-base text-[#000005] font-medium flex items-center gap-4">
				0000000000
				<!--TODO add copy functionlaiy -->
				<button class="cursor-pointer"><img src="@/assets/img/icons/wallet/copy.svg" alt=""></button>
			</p>
		</div>

		<div class="flex flex-col">
			<p class="m-0 text-[#6E717C] text-sm">Account Name</p>
			<p class="m-0 text-base text-[#000005] font-medium">
				Shuttler Ng
			</p>
		</div>
		<p class="m-0 text-sm text-[#B9BCC8]">Transfer money to the bank account above to fund your wallet, your wallet will be
			credited automatically once payment is verified.</p>
		<button
			class="bg-black w-fit border-0 rounded-lg whitespace-nowrap py-2 text-white px-4 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-30"
			@click="activateWallet">
			Activate wallet
		</button>
		<div class="absolute rounded-xl top-0 left-0 w-full h-full z-20 overlay"></div>
	</div>
</template>

<script setup>
import { useClipboard } from '@vueuse/core'
import { ref, defineProps, defineEmits } from 'vue'
import Swal from 'sweetalert2'


const source = ref('')
const { copy, copied, isSupported } = useClipboard({ source })
const emit = defineEmits(['activation'])
const props = defineProps(['obj'])


const activateWallet = () => {
	emit('activation')
}

const copyData = () => {
	source.value = props.obj[0].account_number
	if (isSupported) {
		copy(source.value)
		Swal.fire({ icon: 'success', title: 'Copied', text: 'Successfully copied to clipboard' })
	} else {
		Swal.fire({ icon: 'error', title: 'Error', text: 'Could not copy to clipboard' })
	}
}
</script>

<style scoped>p {
	margin: 0 !important;
}

.overlay {
	background-color: rgba(255, 255, 255, 0.05);
	;
	backdrop-filter: blur(5px);
}</style>